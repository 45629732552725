* {
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
}

html,
body {
  background-color: #74EBD5;
  background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
    height: 100%;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: inherit;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
